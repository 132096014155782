import {Button, TableProps} from "antd";
import React from "react";

type ExpensesTableColumnsProps = {
    onClick(categoryId: string): void
}
export const ExpensesTableColumns = (
    {onClick}: ExpensesTableColumnsProps
): TableProps<{}>['columns'] => {

    return [
        {
            title: 'Category',
            dataIndex: 'categoryName',
            key: 'categoryName',
            render: (text: string | null) => {
                return text ?? 'Uncategorized';
            }
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            align: 'right' as const,
            render: (amount: number) => {
                return new Intl.NumberFormat('it-IT', {
                    style: 'currency',
                    currency: 'EUR'
                }).format(amount);
            }
        },
        {
            title: '',
            width: 100,
            key: 'actions',
            render: (record: any) => (
                <Button size="small" color="primary" variant="outlined"
                        onClick={() => onClick(record.categoryId)}>Show</Button>
            ),
        }
    ];
}