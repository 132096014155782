import React, {useEffect} from "react"
import {Button, Checkbox, Form, Input} from "antd"
import {FormComponentProps} from "../../components/FormComponentProps";
import {useSelector} from "react-redux";
import {RootState} from "../../store/store";


const CategoryForm: React.FC<FormComponentProps> = (
    {
        item,
        onCreate,
        onUpdate,
        onClose,
    }
) => {
    const createCategoryState = useSelector((state: RootState) => state.createCategory.status);
    const updateCategoryState = useSelector((state: RootState) => state.updateCategory.status);

    const [form] = Form.useForm();

    const onFinish = async (data: any) => {
        if (item) {
            onUpdate(item.id!, data)
        } else {
            onCreate(data);
        }
    }

    const close = () => {
        form.resetFields();
        onClose();
    }

    useEffect(() => {
        form.resetFields()
        if (item) {
            form.setFieldsValue(item);
        }
    }, [item, form]);

    useEffect(() => {
        if (createCategoryState == 'COMPLETED' || updateCategoryState == 'COMPLETED')
            form.resetFields();
    }, [createCategoryState, updateCategoryState]);

    return (
        <Form labelCol={{span: 8}}
              wrapperCol={{span: 16}}
              style={{maxWidth: 600, marginTop: '24px'}}
              initialValues={item ? item : {}}
              onFinish={onFinish}
              form={form}>

            <Form.Item label="Name" name="name" rules={[{required: true, message: 'Category name is required'}]}>
                <Input data-testid="input-category-name"/>
            </Form.Item>
            <Form.Item name="includeInExpenses" valuePropName="checked" wrapperCol={{offset: 8, span: 16}}>
                <Checkbox>Include in Expenses</Checkbox>
            </Form.Item>
            <Form.Item wrapperCol={{offset: 8, span: 16}} style={{marginBottom: 0}}>
                <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                    <Button style={{marginRight: '8px'}} onClick={close}>Cancel</Button>
                    <Button type="primary" htmlType="submit" data-testId="btn-confirm">Confirm</Button>
                </div>
            </Form.Item>
        </Form>
    )
}

export default CategoryForm