import React, {useEffect, useState} from "react";
import {ExpensesTable} from "./ExpensesTable";
import {useAppAsyncDispatch} from "../../hooks/useAppAsyncDispatch";
import {fetchExpensesGroupByCategory} from "../../features/expenses/getAll";
import {useSelector} from "react-redux";
import {updateParams} from "../../utils/params";
import queryString from "query-string";
import {useNavigate} from "react-router-dom";
import ExpensesChart from "./ExpensesChart";
import {Card, Empty} from "antd";
import ExpensesFilters from "./ExpensesFilters";
import useFilters from "../../hooks/useFilters";

const ExpensesPage = () => {

    const dispatch = useAppAsyncDispatch();
    const isLoading = useSelector((state: any) => state.fetchExpensesGroupByCategory.status === 'RUNNING');
    const data = useSelector((state: any) => state.fetchExpensesGroupByCategory.data);
    const fetchExpenses = (params?: any) => dispatch(fetchExpensesGroupByCategory(params));
    const [filters, setFilters] = useState<any>(useFilters('expensesFilters', {}));
    const navigate = useNavigate();

    const onFilterChange = (changes: any) => {
        const updatedParams = updateParams(filters, changes);
        setFilters(updatedParams);
        navigate({search: new URLSearchParams(updatedParams).toString()});
    }

    const openMovements = (categoryId: string) => {
        const updatedCategoryId = categoryId === null ? 'none' : categoryId;
        const updatedParams = {...filters, categoryId: updatedCategoryId};
        navigate({
            pathname: '/movements',
            search: queryString.stringify(updatedParams)
        });
    }

    const hasData = data && data.length > 0;

    useEffect(() => {
        fetchExpenses(filters);
    }, [filters]);

    return (
        <Card title={'Expenses'}>
            <ExpensesFilters filters={filters} onChange={onFilterChange}/>
            {hasData ? (
                <div style={{display: 'flex', alignItems: 'flex-start'}}>
                    <div style={{flex: 1, padding: '10px'}}>
                        <ExpensesTable isLoading={isLoading} data={data} onClick={openMovements}/>
                    </div>
                    <div style={{flex: 1, padding: '10px'}}>
                        <ExpensesChart isLoading={isLoading} data={data}/>
                    </div>
                </div>
            ) : (
                <Empty/>
            )}
        </Card>
    );
}

export default ExpensesPage;