import React from "react";
import {ExpensesTableColumns} from "./ExpensesTable-Columns";
import {Table as AntTable} from "antd";

type ExpensesTableProps = {
    isLoading: boolean,
    data: any,
    onClick(categoryId: string): void
}
export const ExpensesTable = (
    {isLoading, data, onClick}: ExpensesTableProps
) => {
    const columns = ExpensesTableColumns({onClick});

    return (
        <AntTable dataSource={data} columns={columns}
                  pagination={false}
                  rowKey="id" loading={isLoading}/>
    )
}