import {DatePicker, Select, Space, TimeRangePickerProps} from "antd";
import {Wallet} from "../../types/Wallet";
import React, {useEffect, useState} from "react";
import dayjs from "dayjs";
import {getWalletsApi} from "../../api/entities/wallets";
import {useNavigate} from "react-router-dom";
import queryString from "query-string";
import {endOfMonth, endOfYear, startOfMonth, startOfYear} from "../../utils/dateJsUtils";

type ExpensesFiltersProps = {
    filters?: any
    onChange: (changes: any) => void
}
const ExpensesFilters = (
    {onChange, filters}: ExpensesFiltersProps
) => {

    const {RangePicker} = DatePicker;
    const [wallets, setWallets] = useState<Wallet[]>([]);
    const navigate = useNavigate();

    const handleFilterChange = (changes: any) => {
        const updatedParams = {...filters, ...changes};
        navigate({search: queryString.stringify(updatedParams)});
        onChange(updatedParams);
        localStorage.setItem('expensesFilters', JSON.stringify(updatedParams));
    }

    useEffect(() => {
        const fetchFiltersData = async () => {
            const walletsData = await getWalletsApi({});
            setWallets(walletsData);
        }
        fetchFiltersData().then();
    }, []);

    const onWalletFilterChange = (value: string) => {
        handleFilterChange({walletId: value});
    }

    const onDateRangeFilterChange = (dates: [dayjs.Dayjs | null, dayjs.Dayjs | null] | null) => {
        if (dates === null) {
            handleFilterChange({startDate: undefined, endDate: undefined});
            return;
        }
        const [start, end] = dates;
        if (start && end) {
            handleFilterChange({
                startDate: start.format('YYYY-MM-DD'),
                endDate: end.format('YYYY-MM-DD')
            });
        }
    };

    const rangePresets: TimeRangePickerProps['presets'] = [
        {label: 'Current month', value: [startOfMonth(), endOfMonth()]},
        {label: 'Current year', value: [startOfYear(), endOfYear()]},
        {label: 'Last 7 Days', value: [dayjs().add(-7, 'd'), dayjs()]},
        {label: 'Last 14 Days', value: [dayjs().add(-14, 'd'), dayjs()]},
        {label: 'Last 30 Days', value: [dayjs().add(-30, 'd'), dayjs()]},
        {label: 'Last 90 Days', value: [dayjs().add(-90, 'd'), dayjs()]},
    ];

    return (
        <Space style={{marginBottom: '20px', justifyContent: 'space-between', width: '100%'}}>
            <Space>
                <Select data-testid='filter-expenses-wallet'
                        style={{minWidth: '200px'}}
                        placeholder='Filter by wallet'
                        value={filters.walletId as string || null}
                        onChange={onWalletFilterChange}>
                    <Select.Option key={'null-wallet'} value={null}>All wallets</Select.Option>
                    {wallets?.map((wallet: Wallet) => (
                        <Select.Option key={wallet.id} value={wallet.id} data-testid={`filter-${wallet.id}`}>
                            {wallet.name}
                        </Select.Option>
                    ))}
                </Select>
                <RangePicker data-testid='filter-expenses-date-range'
                             placeholder={['Start date', 'End date']}
                             style={{marginLeft: '20px'}}
                             mode={['date', 'date']}
                             presets={rangePresets}
                             value={filters.startDate && filters.endDate ? [dayjs(filters.startDate as string), dayjs(filters.endDate as string)] : null}
                             onChange={onDateRangeFilterChange}/>
            </Space>

        </Space>
    )
}

export default ExpensesFilters;