import {DatePicker, Input, Select, Space, TimeRangePickerProps} from "antd";
import {Wallet} from "../../types/Wallet";
import {Category} from "../../types/Category";
import React, {useEffect, useState} from "react";
import {debounce} from "lodash";
import dayjs from "dayjs";
import {getWalletsApi} from "../../api/entities/wallets";
import {getCategoriesApi} from "../../api/entities/categories";
import {useNavigate} from "react-router-dom";
import queryString from "query-string";
import {endOfMonth, endOfYear, startOfMonth, startOfYear} from "../../utils/dateJsUtils";

type MovementsFiltersProps = {
    filters?: any
    onChange: (changes: any) => void
}
const MovementsFilters = (
    {onChange, filters}: MovementsFiltersProps
) => {

    const {RangePicker} = DatePicker;
    const [wallets, setWallets] = useState<Wallet[]>([]);
    const [categories, setCategories] = useState<Category[]>([]);
    const navigate = useNavigate();

    useEffect(() => {

        const fetchFiltersData = async () => {
            const [walletsData, categoriesData] = await Promise.all([
                getWalletsApi({}),
                getCategoriesApi()
            ]);
            setWallets(walletsData);
            setCategories(categoriesData);
        }

        fetchFiltersData().then();
    }, []);

    const handleFilterChange = (changes: any) => {
        const updatedParams = {...filters, ...changes};
        navigate({search: queryString.stringify(updatedParams)});
        onChange(updatedParams);
        const {page, pageSize, ...filtersToSave} = updatedParams;
        localStorage.setItem('movementsFilters', JSON.stringify(filtersToSave));
    }

    const onWalletFilterChange = (value: string) => {
        handleFilterChange({walletId: value});
    }

    const onCategoryFilterChange = (value: string) => {
        handleFilterChange({categoryId: value});
    }

    const onTypeFilterChange = (value: string) => {
        handleFilterChange({type: value});
    }

    const onDescriptionFilterChange = debounce((e: React.ChangeEvent<HTMLInputElement>) => {
        handleFilterChange({description: e.target.value});
    }, 500);

    const onDateRangeFilterChange = (dates: [dayjs.Dayjs | null, dayjs.Dayjs | null] | null) => {
        if (dates === null) {
            handleFilterChange({startDate: undefined, endDate: undefined});
            return;
        }
        const [start, end] = dates;
        if (start && end) {
            handleFilterChange({
                startDate: start.format('YYYY-MM-DD'),
                endDate: end.format('YYYY-MM-DD')
            });
        }
    };

    const rangePresets: TimeRangePickerProps['presets'] = [
        {label: 'Current month', value: [startOfMonth(), endOfMonth()]},
        {label: 'Current year', value: [startOfYear(), endOfYear()]},
        {label: 'Last 7 Days', value: [dayjs().add(-7, 'd'), dayjs()]},
        {label: 'Last 14 Days', value: [dayjs().add(-14, 'd'), dayjs()]},
        {label: 'Last 30 Days', value: [dayjs().add(-30, 'd'), dayjs()]},
        {label: 'Last 90 Days', value: [dayjs().add(-90, 'd'), dayjs()]},
    ];

    const categoriesOptions = [
        {value: null, label: 'All categories'},
        {value: 'none', label: 'Uncategorized'},
        ...categories.map((category: Category) => (
            {value: category.id, label: category.name}
        ))
    ];

    return (
        <Space style={{marginBottom: '20px', justifyContent: 'space-between', width: '100%'}}>
            <Space>
                <Select data-testid='filter-movement-wallet'
                        style={{minWidth: '200px'}}
                        placeholder='Filter by wallet'
                        value={filters.walletId as string || null}
                        onChange={onWalletFilterChange}>
                    <Select.Option key={'null-wallet'} value={null}>All wallets</Select.Option>
                    {wallets?.map((wallet: Wallet) => (
                        <Select.Option key={wallet.id} value={wallet.id} data-testid={`filter-${wallet.id}`}>
                            {wallet.name}
                        </Select.Option>
                    ))}
                </Select>
                <Select data-testid='filter-movement-category'
                        style={{marginLeft: '20px', minWidth: '200px'}}
                        placeholder='Filter by category'
                        showSearch={true}
                        value={filters.categoryId as string || null}
                        filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        onChange={onCategoryFilterChange}
                        options={categoriesOptions}>
                </Select>
                <Select data-testid='filter-movement-type'
                        style={{marginLeft: '20px', minWidth: '200px'}}
                        placeholder='Filter by type'
                        value={filters.type as string || null}
                        onChange={onTypeFilterChange}>
                    <Select.Option key={'ALL'} value={null}>Incomes and expenses</Select.Option>
                    <Select.Option key={'INCOME'} value={'INCOME'}>Only Incomes</Select.Option>
                    <Select.Option key={'EXPENSE'} value={'EXPENSE'}>Only Expenses</Select.Option>
                </Select>
                <RangePicker data-testid='filter-movement-date-range'
                             placeholder={['Start date', 'End date']}
                             style={{marginLeft: '20px'}}
                             mode={['date', 'date']}
                             presets={rangePresets}
                             value={filters.startDate && filters.endDate ? [dayjs(filters.startDate as string), dayjs(filters.endDate as string)] : null}
                             onChange={onDateRangeFilterChange}/>
            </Space>

            <Input data-testid='filter-movement-description'
                   style={{marginRight: '20px', minWidth: '100px'}}
                   placeholder='Filter by description'
                   onChange={onDescriptionFilterChange}/>
        </Space>
    )
}

export default MovementsFilters;