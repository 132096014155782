import queryString from "query-string";
import {useLocation} from "react-router-dom";

const useFilters = (localStorageKey: string, defaultValues: any) => {
    const location = useLocation();
    const loadFromQueryStringAndLocalStorage = () => {
        const fromQuery = queryString.parse(location.search)
        const fromLocalStorage = localStorage.getItem(localStorageKey);
        const filters = fromLocalStorage ? JSON.parse(fromLocalStorage) : defaultValues || {};
        return {...filters, ...fromQuery};
    }

    return loadFromQueryStringAndLocalStorage();
}

export default useFilters;