import React from "react";
import Chart from "react-google-charts";


type ExpensesChartProps = {
    data: any,
    isLoading: boolean
}

const ExpensesChart = (
    {data}: ExpensesChartProps
) => {

    const chartData = [
        ['Category', 'Amount'],
        ...data.map((item: any) => [item.categoryName ?? "Uncategorized", item.amount])
    ]

    const options = {
        pieHole: 0.4,
        is3D: false,
        sliceVisibilityThreshold: 0.05
    };

    return (
        <Chart
            chartType="PieChart"
            options={options}
            data={chartData}
            width="100%"
            height="400px"
        />
    )
}

export default ExpensesChart;