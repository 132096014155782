import axios from "axios";
import {axiosToApiError} from "../types/ApiError";
import {paramsToQueryParams} from "../utils/params";
import {auth} from "../firebase";


export const getApi = async (url: string, params?: any): Promise<any> => {
    try {
        const queryParams = params ? paramsToQueryParams(params) : '';
        const authHeader = await auth.currentUser?.getIdToken();
        const apiHeaders = {Authorization: `Bearer ${authHeader}`}
        const response = await axios.get(`${url}${queryParams}`, {headers: apiHeaders});
        return response.data;
    } catch (error: any) {
        throw axiosToApiError(error);
    }
}