import dayjs from "dayjs";

function startOfMonth(): dayjs.Dayjs {
    return dayjs().startOf('month');
}

function endOfMonth(): dayjs.Dayjs {
    return dayjs().endOf('month');
}

function startOfYear(): dayjs.Dayjs {
    return dayjs().startOf('year');
}

function endOfYear(): dayjs.Dayjs {
    return dayjs().endOf('year');
}

export {startOfMonth, endOfMonth, startOfYear, endOfYear};