import React from "react";
import CategoryColumns from "./CategoriesColumns";
import Table from "../../components/Table";
import {confirmDelete} from "../../utils/confirmDelete";
import {message} from "antd";
import {deleteCategoryApi} from "../../api/entities/categories";
import CategoriesFilters from "./CategoriesFilters";

type CategoriesTableProps = {
    onDeleted(): void,
    onEdit(id: string): void,
    categories: any,
    isLoading: boolean,
    onChange(changes: any): void
}
const CategoriesTable = (
    {onDeleted, onEdit, categories, isLoading, onChange}: CategoriesTableProps
) => {

    const onDelete = async (id: string) => {
        confirmDelete("Are you sure to delete this category?", async () => {
            try {
                await deleteCategoryApi(id);
                message.success('Category deleted');
                onDeleted();
            } catch (err: any) {
                message.error(err.message);
            }
        });
    }
    const columns = CategoryColumns({onDelete, onEdit});

    console.log('CategoriesTable', categories);

    return (
        <Table title="Categories" columns={columns} data={categories} loading={isLoading}>
            <CategoriesFilters onChange={onChange}/>
        </Table>
    )
}

export default CategoriesTable;